import React, { useState, useRef } from "react";
import "../../assets/shared/css/inputFieldCopy.css";

import { base10to64 } from "../../helper/functions";

function InputFieldCopy({shortenLinkID, userKey, idConverted}) {
	let authed = false;
	let verified = false;
	// Make sure we dont assign null values to authed & verified 
	if (localStorage.getItem("userAuth") != null) {
		const userAuth = JSON.parse(localStorage.getItem("userAuth"));
		authed = userAuth.authed;
		verified = userAuth.verified;
	}

	const [clicked, setClicked] = useState(false);
	const textAreaRef = useRef(null);


	const copiedMessageStyle = clicked ? "🎉Copied!" : "Copy";
	function clickToCopy(e) {
		textAreaRef.current.select();
		document.execCommand("copy");
		setClicked(true);
	}

	const inputFieldId = authed && verified ? "originalUrlForm" : "aelperLink"
	let aelperLink = '';

	if (!idConverted) {
		shortenLinkID = base10to64(shortenLinkID)
	}

	if(authed && verified){
		if (userKey) {
			aelperLink = `https://aelper.com/l/${userKey}/${shortenLinkID}`
		} else {
			aelperLink = `Loading...`
		}
	} else {
		aelperLink = `https://aelper.com/p/${shortenLinkID}`
	}
	return (
		<div>
			<button
				id={inputFieldId}
				className="cursor-pointer  bg-gray-800 text-white  p-2  roboto  text-left rounded-md h-16"
				onClick={clickToCopy}
			>
				<input
					// id="shortenUrl"
					id={shortenLinkID}
					ref={textAreaRef}
					value={aelperLink}
					className="bg-gray-800 inline outline-none active:outline-none focus:outline-none cursor-pointer openSans text-sm hover:underline"
					readOnly="readonly"
					
				/>
				<span
					className="ml-4 text-gray-400 roboto"
					role="img"
					aria-label="copied-emoji"
				>
					{copiedMessageStyle}
				</span>
			</button>
		</div>
	);
}

export default InputFieldCopy;
