import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { firebaseFirefunction } from "../firebase/config.js";
import InputFieldCopy from "../components/shared/InputFieldCopy";
import SharingBtn from "../components/shared/SharingBtn";

import { calculateDays } from "../helper/functions";

import AnalysisDetails from "../components/shared/AnalysisDetails";

function PublicLinkInfoPage({ props }) {
	console.log('inside public page')
	let publicLinkId = props.match.params.id;
	// let publicLink = `aelper.com/p/${publicLinkId}`
	let getPCounts = firebaseFirefunction.httpsCallable("getPCounts");

	const [linkInfo, setLinkInfo] = useState(null);

	// Obtain public link info & convert UNIX time to age in days
	useEffect(() => {
		getPCounts({ pkey: publicLinkId }).then((result) => {
			const resultArray = result.data.response;
			resultArray.age = calculateDays(resultArray.age);
			setLinkInfo(resultArray);
			console.log(resultArray.cc);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="backdrop">
			<div id="modalContainer" className="rounded-lg p-6 sm:p-8 overflow-y-auto">
				<p className="roboto grey-text mb-16">
					This link is public and will expire in
					<span className="font-black"> 2 weeks</span>. To create
					persistent links that are private, please
					<Link
						id="publicLinkStatsGoSignIn"
						to="/user/auth"
						className="lightBlue hover:underline"
					>
						{" "}
						sign in
					</Link>
				</p>
				<h2 className="roboto   text-2xl mb-4">
					Statistics
					<hr/>
				</h2>
				<AnalysisDetails linkInfo={linkInfo} />



				<h2 className="roboto   text-2xl mb-4 mt-16">
					Aelperlink
					<hr/>
				</h2>
                <div className="">
                    <div className="aelperLink">
                        <InputFieldCopy  shortenLinkID={publicLinkId} idConverted={true} />
                        <div className="mt-4"></div>
                        <SharingBtn shortenLinkID={publicLinkId} idConverted={true} />
                    </div>
                </div>
				

				<Link
					id="publicLinkStatsGoBackBtn"
					to="/"
					className="block py-2 px-2 mt-8  rounded text-center ml-auto mr-auto"
				>
					<button className="text-white text-xl font-bold   roboto">
						Go back to home page
					</button>
				</Link>
			</div>
		</div>
	);
}

export default PublicLinkInfoPage;
