import React from "react";

// css import
import "../../assets/landingPage/css/landingPage.css";

function LandingMessage() {
	return (
		<div
			className="grid place-items-center mt-8 md:mt-40 lg:mt-48"
			
		>
			<div className="text-center w-4/5">
				<h1 className="landingPageMainMessage text-2xl  sm:text-4xl md:text-5xl lg:text-6xl">
					Aelper - Link Tracking Tool.
				</h1>
				<h2 className="landingPageSubMessage md:text-lg lg:text-2xl">
					Easily track link metrics with an Aelperlink!
				</h2>
			</div>
			<p className="openSans mt-8 font-black">Try It Out!</p>

			<svg
				className="h-6 w-6 "
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.4}
					d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
				/>
			</svg>
		</div>
	);
}

export default LandingMessage;
