import React, {useState, useEffect} from "react";

import { firebaseFirefunction } from "../../firebase/config";
import InputFieldCopy from "./InputFieldCopy"
import SharingBtn from "./SharingBtn"

import { calculateDays } from "../../helper/functions";
import { base10to64 } from "../../helper/functions";
import AnalysisDetails from "./AnalysisDetails"

function AnalyticsModal({ selectedModal, setSelectedModal, shortenLinkID }) {
	const [linkStats, setLinkStats] = useState(null)
	let authed = false;
	let verified = false;
	// Make sure we dont assign null values to authed & verified 
	if (localStorage.getItem("userAuth") != null) {
		const userAuth = JSON.parse(localStorage.getItem("userAuth"));
		authed = userAuth.authed;
		verified = userAuth.verified;
	}
	
	const handleClick = (e) => {
		if (e.target.classList.contains("backdrop")) {
			setSelectedModal(null);
		}
	};

	let publicLinkMonitor = ""
	if(shortenLinkID){
		let shortenLinkIdBase64 = base10to64(shortenLinkID);
		publicLinkMonitor = `aelper.com/publink/${shortenLinkIdBase64}`
	}


	useEffect(()=>{
		if (authed && verified){
			const getLinkStats = firebaseFirefunction.httpsCallable("getLinkStats")
			getLinkStats({lkey:selectedModal}).then(result => {
				const resultArray = result.data.response
				resultArray.age = calculateDays(resultArray.age)
				setLinkStats(resultArray)

			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	

	

	return (
		<div className="backdrop" onClick={handleClick}>
			{authed & verified ? 
			<div id="modalContainer" className="rounded-lg p-8">
			<AnalysisDetails linkInfo={linkStats}/>
			</div>
			: <div id="publicModalContainer" className="rounded-lg p-2">
					<div className="text-center">
						<InputFieldCopy shortenLinkID={shortenLinkID}/>
						
					</div>
					<div className="text-center mt-1 pl-1rem">
						Monitor at: <a className="w-64 h-40 overflow-y-auto break-words lightBlue pr-6 hover:underline openSans" target="_blank" rel="noopener noreferrer"  href={"https://"+publicLinkMonitor}>{publicLinkMonitor}</a>
					</div>
					
					<div className="pl-4 mt-2">
						<SharingBtn shortenLinkID={shortenLinkID}/>
					</div>
					
					</div>}
			
		</div>
	);
}

export default AnalyticsModal;
