import React from "react";
import { Link } from "react-router-dom";
import "../../assets/userAuth/css/emailVerification.css"

import siteLogo from "../../assets/landingPage/svg/siteLogo.svg";

function EmailSent() {
	return (
		<div className="grid place-items-center mt-12">
			
				<Link to="/">
					<img
						src={siteLogo}
						alt=""
						className="h-16 cursor-pointer mb-6"
					/>
				</Link>
			
            <div id="confirmEmailMsg" className="border-2 border-solid border-gray-300 py-6 px-6 w-4/5 mb-4 roboto text-lg">
                A verification email has been sent to you. Verify your email before signing in.
            </div>
            <a  id="confirmEmailBtn" href="mailto:" className="block py-4 px-4 w-4/5 rounded text-center">
                <button className="text-white text-xl font-bold   roboto">Confirm email address</button>
            </a>
            
		</div>
	);
}

export default EmailSent;
