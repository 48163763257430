import React from "react";
import OriginalUrlForm from "../components/shared/OriginalUrlForm";
import LandingMessage from "../components/landing/LandingMessage";
import LandingGraphics from "../components/landing/LandingGraphics";
function LandingPage({setSelectedModal, setShortenLink}) {

	return (
		<div>
			
			<LandingMessage />
			<OriginalUrlForm setSelectedModal={setSelectedModal} setShortenLink={setShortenLink} landingPage={true} />
			<LandingGraphics />
		</div>
	);
}

export default LandingPage;
