import React from 'react'
import { Link } from "react-router-dom";


function EmailVerified() {
    return (
        <div className="grid place-items-center mt-12">
            <h2 className="roboto text-3xl mb-4 font-medium">You're verified!</h2>
            <Link id="emailVerifiedBtn" to="/user/dashboard" className="block py-4 px-4 w-4/5 rounded text-center">
                <button  className="text-white text-xl font-bold    roboto">Go back to your dashboard</button>
            </Link>
            

        </div>
    )
}

export default EmailVerified
