import React  from "react";
import EmailSent from "../components/emailVerification/EmailSent";
import EmailVerified from "../components/emailVerification/EmailVerified";
import { Redirect, Route } from "react-router-dom";

const EmailVerification = () => {
	let authed = false;
	let verified = false;
	// Make sure we dont assign null values to authed & verified
	if (localStorage.getItem("userAuth") != null) {
		const userAuth = JSON.parse(localStorage.getItem("userAuth"));
		authed = userAuth.authed;
		verified = userAuth.verified;
	}
	
	return (
		<Route
			render={() => {
				if (authed && verified) {
					return <EmailVerified />;
				} else if (authed) {
					return <EmailSent />;
				} else {
					return (
						<Redirect
							to={{
								pathname: "/user/auth",
							}}
						/>
					);
				}
			}}
		/>
	);
};

export default EmailVerification;
