import { useState, useEffect } from "react";
import { firebaseFirefunction } from "../firebase/config";

const useGetLkeys = (refresh) => {

	const [lKeys, setLKeys] = useState([]);
	const [loadingStatus, setLoadingStatus] = useState(true);
	const getLKeys = firebaseFirefunction.httpsCallable("getLKeys");

	useEffect(() => {
		getLKeys().then((result) => {
			setLKeys(result.data.response);
            setLoadingStatus(false)

			// console.log("DEBUG", result.data.response);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresh, setLKeys]);

	return { lKeys, loadingStatus };
};

export default useGetLkeys;
