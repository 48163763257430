import firebase from "firebase/app";
import React, { useState } from "react";
import { Link } from "react-router-dom";

// Custom hooks

import SiteLogo from "../../assets/landingPage/svg/SvgSiteLogo";

const Navbar = () => {
	let authed = false;

	// Make sure we dont assign null values to authed & verified
	if (localStorage.getItem("userAuth") != null) {
		const userAuth = JSON.parse(localStorage.getItem("userAuth"));
		authed = userAuth.authed;
	}

	const [navBarDisplay, setNavBarDisplay] = useState(true);
	const navBarDisplayClass = navBarDisplay
		? "w-full block flex-grow lg:flex lg:items-center lg:w-auto mt-4 sm:mt-0  hidden"
		: "w-full block flex-grow lg:flex lg:items-center lg:w-auto mt-4 sm:mt-0";

	function navBarMenuClick() {
		setNavBarDisplay(!navBarDisplay);
	}

	return (
		<nav className="flex items-center justify-between flex-wrap  p-4 border-2 border-gray-300">
			<div
				className="flex items-center flex-shrink-0 text-white mr-6"
			
			>
				<Link to="/">
					{/* <img
						src={siteLogo}
						alt=""
						className="siteLogo h-10 cursor-pointer"
					/> */}
					<SiteLogo className="siteLogo h-10 cursor-pointer"/>
				</Link>
			</div>
			<div className="block lg:hidden">
				<button
					className="flex items-center px-3 py-2 text-gray-700 hover:text-gray-900 hover:border-black focus:outline-none"
					onClick={navBarMenuClick}
				>
					<svg
						className="fill-current h-6 w-6"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<title>Menu</title>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M4 6h16M4 12h16m-7 6h7"
						/>
					</svg>
				</button>
			</div>
			<div className={navBarDisplayClass}>
				<div className="text-sm lg:flex-grow">
					<a
						href="mailto:dave@aelper.com"
						className="block mt-4 lg:inline-block lg:mt-0 text-gray-700 hover:text-black mr-4"
					>
						Contact
					</a>
					{authed ? (
						<span
							className="block mt-4 lg:inline-block lg:mt-0  text-gray-700 hover:text-black mr-4 cursor-pointer"
							onClick={() => {
								localStorage.setItem(
									"userAuth",
									JSON.stringify({
										authed: false,
										verified: false,
									})
								);
								firebase.auth().signOut();
								window.location.replace(
									`${window.location.origin}/user/auth`
								);
							}}
						>
							Logout
						</span>
					) : null}
				</div>
				<div>
					{authed ? (
						<Link to="/user/dashboard">
							<span className="inline-block text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-600 transition duration-300 mt-4 lg:mt-0">
								<svg
									className="h-6 w-6 inline"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={1.5}
										d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
									/>
								</svg>
								<span className="ml-1 align-middle">
									Dashboard
								</span>
							</span>
						</Link>
					) : (
						<Link to="/user/auth">
							<span className="inline-block text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-600 mt-4 lg:mt-0">
								Register / Login
							</span>
						</Link>
					)}
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
