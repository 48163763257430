// Core firebase packages
import * as firebase from "firebase/app";
import "firebase/auth";
// import "firebase/storage";
// import "firebase/database"; // Realtime database
import "firebase/firebase-functions";
// 3rd party packages

// Firebase config
const firebaseConfig = {
	apiKey: "AIzaSyBfEGGrzW-L7l-tCh1lFdkGXrUKVJeTTXM",
	authDomain: "aelper.com",
	// authDomain: "aelper-1.firebaseapp.com",
	databaseURL: "https://aelper-1.firebaseio.com",
	projectId: "aelper-1",
	storageBucket: "aelper-1.appspot.com",
	messagingSenderId: "898744165112",
	appId: "1:898744165112:web:ea52148a3c955a3fe5510d",
	measurementId: "G-JQXG0H30TT",
};


firebase.initializeApp(firebaseConfig);
const firebaseAuth = firebase.auth();
// const firebaseStorage = firebase.storage();
// const firebaseDatabase = firebase.database();
const firebaseFirefunction = firebase.functions();

// Firebase Auth UI config
const fireAuthUIConfig = {
	callbacks: {
		signInSuccessWithAuthResult: (authResult) => {
			return true;
		},
	},
	queryParameterForSignInSuccessUrl: "signInSuccessUrl",
	signInSuccessUrl: `${window.location.origin}/user/auth`,
	signInFlow: "popup",
	signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		{ provider: "microsoft.com" },
		{
			provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
			requireDisplayName: true,
			signInMethod: "password",
		},
	],
};



export {
	firebaseAuth,
	// firebaseStorage,
	// firebaseDatabase,
	firebaseFirefunction,
	fireAuthUIConfig,
};
