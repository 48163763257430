import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";

import OriginalUrlForm from "../components/shared/OriginalUrlForm";
import LinkInfoBox from "../components/userDashboard/LinkInfoBox";

import { firebaseFirefunction } from "../firebase/config.js";

import useGetLKeys from "../hooks/useGetLKeys";

import loadingIcon from "../assets/shared/svg/loading.svg";

function UserDashboardPage({ setSelectedModal }) {
	let authed = false;
	let verified = false;
	// Make sure we dont assign null values to authed & verified
	if (localStorage.getItem("userAuth") != null) {
		const userAuth = JSON.parse(localStorage.getItem("userAuth"));
		authed = userAuth.authed;
		verified = userAuth.verified;
	}

	const [linkArray, setLinkArray] = useState([]);
	const [loading, setLoading] = useState(true);
	const [refresh, setRefresh] = useState(false);

	// For PWA Links from another apps
	const [givenUrl, setGivenUrl] = useState(null);
	const [givenUrlUsed, setGivenUrlUsed] = useState(0);

	// Firebase hooks
	const { lKeys, loadingStatus } = useGetLKeys(refresh);

	let createUserLink = firebaseFirefunction.httpsCallable("createLink");

	const parsedUrl = new URL(window.location);
	window.addEventListener("DOMContentLoaded", () => {
		setGivenUrl(parsedUrl.searchParams.get("text"));
	});

	useEffect(() => {}, [linkArray]);

	// Loading link boxes for authed users
	useEffect(() => {
		if (authed && verified) {
			if (!loadingStatus) {
				setLinkArray(lKeys);
				setLoading(false);
			} else {
				setLoading(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresh, lKeys, loadingStatus, setLinkArray, setLoading]);

	// Handle event when user shares a link from 3rd party apps
	useEffect(() => {
		if (authed && verified) {
			if (!loadingStatus) {
				setLoading(false);
				setLinkArray(lKeys);
				if (givenUrl != null && givenUrlUsed < 1) {
					setGivenUrlUsed(1);
					// console.log(
					// 	"DEBUG",
					// 	givenUrl,
					// 	givenUrlUsed,
					// 	result.data.response
					// );
					createUserLink({
						targetlink: givenUrl,
						linkid: lKeys.length,
					}).then((result) => {
						setRefresh(!refresh);
					});
				} else {
					setGivenUrlUsed(1);
				}
			} else {
				setLoading(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [givenUrl, refresh, givenUrlUsed, lKeys, loadingStatus]);

	return (
		<Route
			render={() => {
				if (authed && verified) {
					return (
						<div className="mt-12 mb-4">
							{loading ? (
								<img
									src={loadingIcon}
									alt="loading"
									className="block mx-auto mt-20"
								/>
							) : (
								<OriginalUrlForm
									newLinkId={linkArray.length}
									setRefresh={setRefresh}
									refresh={refresh}
								/>
							)}
							<div id="linkInfoBoxList" className="">
								{linkArray
									.slice()
									.reverse()
									.map((linkInfo) => (
										<LinkInfoBox
											key={linkInfo.k + 1}
											linkInfo={linkInfo}
											setSelectedModal={setSelectedModal}
										/>
									))}
							</div>
						</div>
					);
				} else if (authed) {
					return (
						<Redirect
							to={{
								pathname: "/user/email/verification",
							}}
						/>
					);
				} else {
					return (
						<Redirect
							to={{
								pathname: "/user/auth",
							}}
						/>
					);
				}
			}}
		/>
	);
}

export default UserDashboardPage;
