import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// Import components
import Navbar from "./components/shared/Navbar";
import LandingPage from "./pages/LandingPage";
import UserAuthPage from "./pages/UserAuthPage";
import UserDashboardPage from "./pages/UserDashboardPage";
import PublicLinkInfoPage from "./pages/PublicLinkInfoPage";
import EmailVerification from "./pages/EmailVerification";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import UserGuide from "./pages/UserGuide";
import ShareTarget from "./pages/ShareTarget";
import AnalysticsModal from "./components/shared/AnalyticsModal";


import NotFound from "./pages/NotFound";

import firebase from "firebase/app";
import { firebaseAuth } from "./firebase/config";

import "./assets/userDashboard/css/userDashboard.css";
import "./assets/userDashboard/css/modal.css";
import "./assets/shared/css/colors.css";
// Hooks

// SVG Imports
import SvgUserGuide from "./assets/userGuide/svg/SvgUserGuide";

function App() {
	const [selectedModal, setSelectedModal] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [shortenLinkID, setShortenLink] = useState(null);

	useEffect(() => {
		if (selectedModal || selectedModal === 0) {
			setModalOpen(true);
		} else {
			setModalOpen(false);
		}
	}, [selectedModal]);

	useEffect(() => {
		firebase.auth().onAuthStateChanged(
			(user) => {
				if (user) {
					localStorage.setItem(
						"userAuth",
						JSON.stringify({
							authed: true,
							verified: firebaseAuth.currentUser.emailVerified,
						})
					);

					if (!firebaseAuth.currentUser.emailVerified) {
						user.sendEmailVerification().then(() => {
							console.log("Email sent!");
						});
					}
					console.log("User Authed!");
				} else {
					localStorage.setItem(
						"userAuth",
						JSON.stringify({ authed: false, verified: false })
					);
					console.log("User Logout");
				}
			},
			(err) => {
				console.log(err);
			}
		);
	}, []);
	// First, ask the Permissions API if we have some kind of access to
	// the "clipboard-read" feature.

	return (
		<Router>
			<div className="">
				<Link to="/userGuide">
					<div className="fixed  userGuide-btn border rounded-full text-white bg-indigo-500 cursor-pointer px-4  border-indigo-500 transition duration-300 hover:bg-indigo-600 hover:border-indigo-600 ">
						<div className="flex flex-col justify-center">
							<SvgUserGuide className="inline-block mx-auto" />
							<span className="inline-block">Guide</span>
						</div>
					</div>
				</Link>

				<Navbar />

				<Switch>
					<Route
						path="/user/dashboard"
						exact
						// component={UserDashboardPage}
						render={(props) => (
							<UserDashboardPage
								setSelectedModal={setSelectedModal}
							/>
						)}
					/>
					<Route
						path="/"
						exact
						render={(props) => (
							<LandingPage
								setSelectedModal={setSelectedModal}
								setShortenLink={setShortenLink}
							/>
						)}
					/>
					<Route path="/user/auth" exact component={UserAuthPage} />

					<Route
						path="/user/email/verification"
						exact
						component={EmailVerification}
					/>
					<Route
						path="/privacyPolicy"
						exact
						component={PrivacyPolicy}
					/>
					<Route path="/userGuide" exact component={UserGuide} />
					<Route
						path="/share-target"
						exact
						render={(props) => <ShareTarget />}
					/>

					{/* Publin link info route */}
					<Route
						path="/publink/:id"
						render={(props) => <PublicLinkInfoPage props={props} />}
					/>

					<Route path="" component={NotFound} />
				</Switch>

				{modalOpen && (
					<AnalysticsModal
						selectedModal={selectedModal}
						setSelectedModal={setSelectedModal}
						shortenLinkID={shortenLinkID}
					/>
				)}
			</div>
		</Router>
	);
}

export default App;
