import React from "react";

import DoughnutChart from "../charts/DoughnutChart"

function AnalysisDetails({linkInfo}) {
	return (
		<div>
			<div className=" grid grid-cols-1 gap-2 md:place-items-center sm:grid-cols-2 md:grid-cols-3 mb-8">
				<section>
					<p className="roboto grey-text">Total Clicks</p>
					<p>
						<span className="align-middle font-bold text-3xl mr-2 roboto text-purple-500">
							{linkInfo ? linkInfo.c : "..."}
						</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="icon icon-tabler icon-tabler-click"
							width="30"
							height="30"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="#808080"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<line x1="3" y1="12" x2="6" y2="12" />
							<line x1="12" y1="3" x2="12" y2="6" />
							<line x1="7.8" y1="7.8" x2="5.6" y2="5.6" />
							<line x1="16.2" y1="7.8" x2="18.4" y2="5.6" />
							<line x1="7.8" y1="16.2" x2="5.6" y2="18.4" />
							<path d="M12 12l9 3l-4 2l-2 4l-3 -9" />
						</svg>
					</p>
				</section>

				<section>
					<p className="roboto grey-text">Unique Clicks</p>
					<p>
						<span className="align-middle font-bold text-3xl mr-2 roboto text-indigo-500">
							{linkInfo ? linkInfo.u : "..."}
						</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="icon icon-tabler icon-tabler-user"
							width="30"
							height="30"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="#808080"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<circle cx="12" cy="7" r="4" />
							<path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
						</svg>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="icon icon-tabler icon-tabler-click"
							width="30"
							height="30"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="#808080"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<line x1="3" y1="12" x2="6" y2="12" />
							<line x1="12" y1="3" x2="12" y2="6" />
							<line x1="7.8" y1="7.8" x2="5.6" y2="5.6" />
							<line x1="16.2" y1="7.8" x2="18.4" y2="5.6" />
							<line x1="7.8" y1="16.2" x2="5.6" y2="18.4" />
							<path d="M12 12l9 3l-4 2l-2 4l-3 -9" />
						</svg>
					</p>
				</section>

				<section>
					<p className="roboto grey-text">Age(Days)</p>
					<p>
						<span className="align-middle font-bold text-3xl mr-2 roboto text-red-400">
							{linkInfo ? linkInfo.age : "..."}
						</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="align-middle icon icon-tabler icon-tabler-calendar-event"
							width="30"
							height="30"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="#808080"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<rect x="4" y="5" width="16" height="16" rx="2" />
							<line x1="16" y1="3" x2="16" y2="7" />
							<line x1="8" y1="3" x2="8" y2="7" />
							<line x1="4" y1="11" x2="20" y2="11" />
							<rect x="8" y="15" width="2" height="2" />
						</svg>
					</p>
				</section>
			</div>

			{linkInfo ? (
				<DoughnutChart geoClicks={linkInfo.cc} />
			) : (
				<p className="text-center font-bold text-5xl roboto text-gray-500">
					...
				</p>
			)}
		</div>
	);
}

export default AnalysisDetails;
