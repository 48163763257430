import React, { useRef } from "react";
import { firebaseFirefunction } from "../../firebase/config";
import { validateUrl } from "../../helper/functions";
import { motion } from "framer-motion";
const inputContainer = {
	hidden: {
		scale: 0,
		opacity: 0,
	},
	visible: {
		scale: 1,
		opacity: 1,
		transition: {
			type: "spring",
			stiffness: 260,
			damping: 100,
		},
	},
};
function OriginalUrlForm({
	setSelectedModal,
	setShortenLink,
	newLinkId,
	setRefresh,
	refresh,
	landingPage,
}) {
	let authed = false;
	let verified = false;
	// Make sure we dont assign null values to authed & verified
	if (localStorage.getItem("userAuth") != null) {
		const userAuth = JSON.parse(localStorage.getItem("userAuth"));
		authed = userAuth.authed;
		verified = userAuth.verified;
	}

	let createPubLink = firebaseFirefunction.httpsCallable("createPubLink");
	let createUserLink = firebaseFirefunction.httpsCallable("createLink");

	const rawLink = useRef(null);




	function fetchUserKey() {
		if (rawLink.current.value === "") {
			navigator.permissions
				.query({ name: "clipboard-read" })
				.then((result) => {
					// If permission to read the clipboard is granted or if the user will
					// be prompted to allow it, we proceed.

					if (
						result.state === "granted" ||
						result.state === "prompt"
					) {
						navigator.clipboard.readText().then((data) => {
							rawLink.current.value = data;
							// wait for data to be retrieved
							const userLink = validateUrl(rawLink.current.value);

							createUserLink({
								targetlink: userLink,
								linkid: newLinkId,
							}).then((result) => {
								setRefresh(!refresh);
							});
							rawLink.current.value = "";
						});
					}
				});
		} else {
			const userLink = validateUrl(rawLink.current.value);

			createUserLink({
				targetlink: userLink,
				linkid: newLinkId,
			}).then((result) => {
				setRefresh(!refresh);
			});
			rawLink.current.value = "";
		}
	}
	function fetchPublicKey() {
		if (authed && verified) {
			alert(
				"☺️ This is for guest users. Please create an aelper link on your dashboard page."
			);
		} else {
			if (rawLink.current.value === "") {
				navigator.permissions
					.query({ name: "clipboard-read" })
					.then((result) => {
						// If permission to read the clipboard is granted or if the user will
						// be prompted to allow it, we proceed.

						if (
							result.state === "granted" ||
							result.state === "prompt"
						) {
							navigator.clipboard.readText().then((data) => {
								rawLink.current.value = data;
								// wait for data to be retrieved
								const userLink = validateUrl(
									rawLink.current.value
								);

								// eslint-disable-next-line no-use-before-define
								createPubLink({ targetlink: userLink }).then(
									(result) => {
										// const base64Id = base10to64(result.data.pkey);
										setShortenLink(result.data.pkey);
										setSelectedModal("publicLink");
									}
								);
								rawLink.current.value = "";
							});
						}
					});
			} else {
				const userLink = validateUrl(rawLink.current.value);

				// eslint-disable-next-line no-use-before-define
				createPubLink({ targetlink: userLink }).then((result) => {
					// const base64Id = base10to64(result.data.pkey);
					setShortenLink(result.data.pkey);
					setSelectedModal("publicLink");
				});
				rawLink.current.value = "";
			}
		}
	}
	const fetchName =
		authed && verified && !landingPage ? fetchUserKey : fetchPublicKey;

	return (
		<motion.div
			className="grid mt-2   place-items-center  "
			variants={inputContainer}
			initial="hidden"
			animate="visible"
		>
			<div className="grid place-items-center relative w-11/12 sm:w-3/4 md:max-w-md lg:max-w-lg">
				<button
					onClick={fetchName}
					className="originalInputLogo absolute text-white h-full rounded-r-full p-2 right-0 transition duration-300 focus:outline-none"
					type="submit"
				>
					Shorten
				</button>
				<input
					ref={rawLink}
					type="text"
					placeholder="Link copied? Just Click 👉"
					className="focus:outline-none hover:shadow-lg focus:shadow-lg shadow appearance-none border rounded  py-2  pl-6 pr-20 text-gray-700 leading-tight w-full  rounded-full h-12 transition duration-300"
				></input>
			</div>
		</motion.div>
	);
}

export default OriginalUrlForm;
