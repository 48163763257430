import React from "react";

import firebase from "firebase/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { fireAuthUIConfig } from "../../firebase/config";

import { Redirect, Route } from "react-router-dom";

function LoginOptions() {
	let authed = false;
	let verified = false;
	// Make sure we dont assign null values to authed & verified 
	if (localStorage.getItem("userAuth") != null) {
		const userAuth = JSON.parse(localStorage.getItem("userAuth"));
		authed = userAuth.authed;
		verified = userAuth.verified;
	}

	return (
		<Route
			render={() => {
				if (authed && verified) {
					return (
						<Redirect
							to={{
								pathname: "/user/dashboard",
							}}
						/>
					);
				} else if (authed) {
					return (
						<Redirect
							to={{
								pathname: "/user/email/verification",
							}}
						/>
					);
				} else {
					return (
						<div>
							{authed ? null : (
								<div
									id="loginOptionBox"
									className="mt-8 rounded-lg   py-12 w-64 m-auto roboto "
								>
									<StyledFirebaseAuth
										uiConfig={fireAuthUIConfig}
										firebaseAuth={firebase.auth()}
									/>
								</div>
							)}
						</div>
					);
				}
			}}
		/>
	);
}

export default LoginOptions;
