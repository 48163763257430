import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { countries } from "country-data";

import { sortGeoClicks } from "../../helper/functions";

function DoughnutChart({ geoClicks }) {
	const [geoData, setGeoData] = useState({});

	console.log("Heello", geoClicks)
	let processedGeoClicks = sortGeoClicks(geoClicks);

	

	useEffect(() => {
		let countryName = [];
		let countryClick = []
		for (let i in processedGeoClicks) {
			if (parseInt(i)+1 === processedGeoClicks.length && processedGeoClicks.length >4) {
				countryName.push("Others");
			} else {
				countryName.push(countries[processedGeoClicks[i][0]].name);
			}
			countryClick.push(processedGeoClicks[i][1])
		}
		setGeoData({
			labels: countryName,
			datasets: [
				{
					label: "Geo-located clicks",
					data: countryClick,
					backgroundColor: [
						"rgba(255, 99, 132, 0.2)",
						"rgba(54, 162, 235, 0.2)",
						"rgba(255, 206, 86, 0.2)",
						"rgba(75, 192, 192, 0.2)",
						"rgba(153, 102, 255, 0.2)",
					],
					borderColor: [
						"rgba(255, 99, 132, 1)",
						"rgba(54, 162, 235, 1)",
						"rgba(255, 206, 86, 1)",
						"rgba(75, 192, 192, 1)",
						"rgba(153, 102, 255, 1)",
					],
					borderWidth: 1,
				},
			],
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="max-w-md mx-auto">
			
			<Doughnut
				title="Geo-Located Clicks"
				data={geoData}
				options={{
					responsive: true,
					maintainAspectRatio: false,
					responsiveAnimationDuration: 1000,
					title: {
						display: true,
						text: "Geo-Located Clicks",
						fontSize: 20,
					},
					legend: {
						display: true,
						position: "right",
					},
				}}
			/>
		</div>
	);
}

export default DoughnutChart;
